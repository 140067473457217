<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="motilidadeOcularExtrinseca"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="6">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  :value="form.fields.olhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('olhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  id="motilidadeOcularExtrinseca-direito"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  :value="form.fields.olhoEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('olhoEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  id="motilidadeOcularExtrinseca-esquerdo"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <button
        class="btn blue-underline button-copy center"
        @click="copyEyeValues"
        v-if="canEdit"
        v-b-tooltip.hover title="Copiar valores O.D para O.E"
      >
        <v-copy-icon />
      </button>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Copy from '@/assets/icons/copy.svg'

export default {
  components: { FormWrapper, EyeFill, 'v-copy-icon' : Copy, },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.motilidadeOcularExtrinseca,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/motilidadeOcularExtrinseca', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.form.fields.olhoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>
